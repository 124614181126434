import { render, staticRenderFns } from "./Settings_city_fenyong.vue?vue&type=template&id=ef3cca84&scoped=true&"
import script from "./Settings_city_fenyong.vue?vue&type=script&lang=js&"
export * from "./Settings_city_fenyong.vue?vue&type=script&lang=js&"
import style0 from "./Settings_city_fenyong.vue?vue&type=style&index=0&id=ef3cca84&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef3cca84",
  null
  
)

export default component.exports